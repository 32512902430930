/**
  * Usages :
   @include texte( rem-calc(12), #FF0000, 400, 0.05rem, rem-calc(12), $body-font-color, center );
   @include texte( rem-calc(12), #000000 );
   @include texte( rem-calc(12), $align: right, $color: green );
   @include texte( $align: right, $color: green, $size: 12 );
*/

@mixin texte( $size: null, $color: null, $weight: null, $spacing: null, $height: null, $family: null, $align: null, $transform: null) {
    font-size: $size;
    color: $color;
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: $height;
    font-family: $family;
    text-align: $align;
    text-transform: $transform;
}

@mixin padd-top {
    padding-top: rem-calc(80);
    @include breakpoint(1200px down) {
        padding-top: rem-calc(80);
    }
    @include breakpoint(768px down) {
        padding-top: rem-calc(60);
    }
}

@mixin padd-bot {
    padding-bottom: rem-calc(80);
    @include breakpoint(1200px down) {
        padding-bottom: rem-calc(80);
    }
    @include breakpoint(768px down) {
        padding-bottom: rem-calc(60);
    }
}

@mixin titles-homepage {
    font-family: $body-font-family-variant;
    font-size: rem-calc(24);
    line-height: rem-calc(36);
    letter-spacing: -0.060em;
    color: $black;
    font-weight: $global-weight-light;
    text-align: center;
    margin-bottom: 0;
}

@mixin titles-homepage-extranet {
    font-family: $body-font-family-variant;
    font-size: rem-calc(25);
    line-height: rem-calc(35);
    color: $primary-color !important;
    font-weight: $global-weight-bold;
    text-align: left;
    text-transform: uppercase;
    @include breakpoint (medium down) {
        font-size: rem-calc(18);
    }
}

@mixin after-links {
    position: relative;
    &:after {
        position: absolute;
        background: $header-menu-selector;
        width: 0;
        height: rem-calc(3);
        left: 0;
        right: 0;
        margin: auto;
        content: "";
        z-index: 1;
        bottom: rem-calc(0);
        transition: 300ms;
    }
}

@mixin p {
    font-family: $body-font-family;
    font-size: rem-calc(16);
    line-height: rem-calc(28);
    color: $textes;
    font-weight: $global-weight-light;
    letter-spacing: 0.025em;
}

@mixin titles-items {
    font-size: rem-calc(16);
    line-height: rem-calc(28);
    color: $primary-color;
    font-weight: $global-weight-bold;
    letter-spacing: 0.01em;
    margin-bottom: rem-calc(15);
    transition: color 300ms;
    &:hover {
        color: $secondary-color;
    }
}

@mixin theme {
    font-size: rem-calc(12);
    line-height: rem-calc(20);
    color: $dark-gray;
    font-weight: $global-weight-light;
    letter-spacing: 0.0075em;
    transition: color 300ms;
    text-transform: uppercase;
    &:hover {
        color: $secondary-color;
    }
}

;@import "sass-embedded-legacy-load-done:330";