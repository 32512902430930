/* Feuille de style chargée pour la version mobile du site ET la version desktop */


/* Debugger */
#debug {
    font-size: 10px;
    background: white;
    padding: 0 2px;
    max-width: 100%;
    text-align: right;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 64536;
    border: 1px solid black;
}

#debug pre {
    white-space: nowrap;
    display: none;
    overflow-x: scroll;
    text-align: left;
}

#debug span {
    display: inline;
}

#debug:hover pre {
    display: block;
}

/* Diverses corrections foundation */
.label a,
.alert-box a {
    color: white;
}

.callout.info {
    @extend  .primary;
}

.gauche:not(.seccode),
img[align="left"]:not(.seccode) {
    margin: 0 15px 15px 0;
    float: left;
    clear: left;
}

.droite:not(.seccode),
img[align="right"]:not(.seccode) {
    margin: 0 0 15px 15px;
    float: right;
    clear: right;
}

.centre:not(.seccode) {
    clear: both;
    margin: 15px auto;
    display: block;
}

figure {
    margin: 0;
}

figure.th {
    line-height: normal;
    background: white;
}

figure.th figcaption {
    font-size: smaller;
    text-align: center;
    padding: 5px;
    background: #f4f4f4;
    border: 1px dotted #dadada;
}

.top-bar-section ul ul {
    display: none;
}

.top,
.print,
.rss {
    display: block;
    width: 45px;
    height: 45px;
    text-indent: -9999px;
}

.top {
    background: url(../images/top.png) no-repeat center;
}

.print {
    background: url(../images/print.png) no-repeat center;
}

.rss {
    background: url(../images/rss.png) no-repeat center;
}

/* Pagination */

ul.pagination li.current a,
ul.pagination li.current button {
    color: #fff !important;
    font-weight: 400;
    border-radius: 0;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.is-dropdown-submenu.js-dropdown-active {
    animation-name: smoothshow;
    animation-duration: 0.7s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

@keyframes smoothshow {
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: auto;
        opacity: 1;
    }
}

;@import "sass-embedded-legacy-load-done:358";