.ext-annonces {
    grid-template-columns:repeat(4, minmax(0, 1fr));
     gap:rem-calc(20); 
     display:grid;
     @include breakpoint(medium down) {
        grid-template-columns:repeat(3, minmax(0, 1fr));
    }
    @include breakpoint(small down) {
        grid-template-columns:repeat(2, minmax(0, 1fr));
    }
    @include breakpoint(475px down) {
        grid-template-columns:1fr;
    }

    &__sidebar {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap:rem-calc(40);
        @include breakpoint(620px down) {
            grid-template-columns:1fr;
        }
    }

    &__left {
        padding-right:rem-calc(20);
        border-right:1px solid $light-gray;
        @include breakpoint(475px down) {
        padding-right:0;
        padding-bottom:rem-calc(20);
        border-bottom:1px solid $light-gray; 
        border-right:none;
        }
    }

    &__right {
        display: flex;
        background:$light-gray;
        padding:rem-calc(20);
        &--container {
            margin:auto;
            @include breakpoint(620px down) {
                margin:0;
            }
        }
    }

    &__maintitle {
        @include titles-homepage-extranet;
        margin-bottom: rem-calc(35);
        display: block;
        padding-left: rem-calc(30);
        @include breakpoint(medium down) {
            padding-left: rem-calc(15);
          }
        a {
            color:$primary-color;
            transition:color 300ms;
            &:hover {
                color:$secondary-color;
            }
        }
    }
    &__items {
        background: $white;
        border: solid 1px $light-gray;
        height: 100%;
        padding:rem-calc(30);
        box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: rem-calc(395);
    }

    &__header {
        gap:rem-calc(5);
    }

    &__title {
        display: block;
        h3 {
            @include titles-items;
            margin:0;
        }
    }

    &__infos, &__texte {
        p {
            font-size: rem-calc(14);
            line-height: rem-calc(26);
        }
    }
    

    &__more {
        font-weight: $global-weight-medium;
        font-size: rem-calc(14);
        line-height: rem-calc(26);
    }

    &__search {
        @include breakpoint(medium down) {
            display: grid;
            margin: auto;
            grid-template-rows: 1fr 1fr 1fr;
            form {
                margin: auto !important;
                width: 100% !important;
            }
        }
    }

    &__buttons {
        @include breakpoint(medium down) {
            width: 100%;
            .button-group {
                display:flex;
                gap:rem-calc(5);
            }
        }
        .button {
            @include breakpoint(medium down) {
                width:100%;
                margin:rem-calc(5) auto;
            }
            @include breakpoint(475px down) {
                padding:rem-calc(10);
            }
            @include breakpoint(320px down) {
                padding:rem-calc(5);
            }
        }
    }

}

.collabs {
    margin-bottom: rem-calc(60);
    .button {
        margin: rem-calc(40) auto rem-calc(20);
        display:table;
    }
}
;@import "sass-embedded-legacy-load-done:379";