.about {
    background: $primary-color;
    padding-top: rem-calc(100);
    @include padd-bot;
    @include breakpoint(large down) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: rem-calc(50);
        width: 100% !important;
    }
    &:not(.show-all) {
        .about__link {
            display: none;
        }
    }
    &.show-all {
        button {
            display: none;
        }
    }
    &__link {
        border: solid 1px $border;
        background: transparent;
        padding: rem-calc(8);
        transition: background-color ease-in-out 300ms, border-color ease-in-out 300ms;
        svg {
            stroke: $secondary-color;
            transition: stroke ease-in-out 300ms;
            transform: rotate(-90deg);
        }
        &:hover {
            background: $secondary-color;
            border-color: $secondary-color;
            svg {
                stroke: white;
            }
        }
    }
    button {
        cursor: pointer;
        font-size: rem-calc(14);
        color: $secondary-color;
    }
    #text-intro-toggle {
        svg {
            width: rem-calc(35);
            height: rem-calc(35);
            padding: rem-calc(8);
            stroke: $secondary-color;
            border: solid 1px $border;
            margin-top: rem-calc(15);
            transition: background-color ease-in-out 300ms, border-color ease-in-out 300ms;
            &:hover {
                background: $secondary-color;
                border-color: $secondary-color;
                stroke: white;
            }
        }
    }
    #text-intro {
        position: relative;
        p,
        li {
            color: $white;
        }
        &.show-all {
            max-height: 999999px;
            transition: max-height ease-in 2s;
        }
        &:not(.show-all) {
            // max-height: 280px;
            overflow: hidden;
            transition: max-height ease-in 2s;
            // &:after {
            //     content: '';
            //     height: 20px;
            //     background: $primary-color;
            //     background: linear-gradient(0deg, $primary-color 0%, $primary-color 17%, rgba(240, 240, 240, 0) 100%);
            //     position: absolute;
            //     bottom: 0;
            //     width: 100%;
            //     z-index: 999;
            // }
        }
        .more {
            color: $secondary-color;
            margin-top: -20px;
            @include breakpoint (medium) {
                float: right;
            }
            &:hover {
                color: darken($secondary-color, 10%);
            }
        }
    }
    h2 {
        @include titles-homepage;
        color: $white;
        text-transform: uppercase;
        text-align: left;
        font-size: rem-calc(30);
        margin-bottom: rem-calc(20);
        margin-top: 0;
        @include breakpoint(768px down) {
            margin-top: rem-calc(20);
        }
        span {
            color: $secondary-color;
        }
    }
}
;@import "sass-embedded-legacy-load-done:367";