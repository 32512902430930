.footer {
    background: $white;
    padding: rem-calc(50 50 30);
    @include breakpoint(1200px down) {
        padding: rem-calc(25);
        padding-bottom: rem-calc(80);
    }
    &__container {
        max-width: rem-calc(1200);
        margin: auto;
        @include breakpoint(small down) {
            margin-bottom: rem-calc(40);
        }
    }
    &__logo {
        display: block;
        margin-bottom: rem-calc(30);
    }
    .col {
        @include breakpoint(425px down) {
            margin-bottom: rem-calc(15);
        }
        &-acces,
        &-horaires {
            padding-top: rem-calc(70);
            @include breakpoint(620px down) {
                padding-top: 0;
            }
        }
        p {
            @include p;
            font-size: rem-calc(14);
            letter-spacing: 0.10em;
            margin-bottom: rem-calc(20);
        }
        &-titles {
            font-family: $body-font-family;
            color: $black;
            font-weight: $global-weight-bold;
            font-size: rem-calc(14);
            line-height: rem-calc(28);
            text-transform: uppercase;
            margin-bottom: rem-calc(10);
        }
        &-rs {
            border: solid 2px $secondary-color;
            border-radius: rem-calc(30);
            display: flex;
            margin: auto rem-calc(5);
            float: left;
            width: rem-calc(45);
            height: rem-calc(45);
            transition: border 300ms;
            &:hover {
                border-color: darken($secondary-color, 15);
                path {
                    fill: darken($secondary-color, 15);
                }
            }
            svg {
                margin: auto;
                width: rem-calc(30);
            }
            path {
                fill: $secondary-color;
                transition: fill 300ms;
            }
        }
        ul {
            margin-left: rem-calc(5);
            &.no-bullet {
                margin-bottom: 0;
            }
        }
        li {
            list-style: none;
            padding-left: rem-calc(10);
            position: relative;
            display: flex;
            @include p;
            margin-bottom: rem-calc(5);
            font-size: rem-calc(14);
            letter-spacing: 0.05em;
            a {
                color: $textes;
                padding-left: rem-calc(10);
                transition: color 300ms;
                &:hover {
                    color: $secondary-color !important;
                }
            }
            &:before {
                content: ">";
                position: absolute;
                left: rem-calc(-5);
                top: 0;
                transition: 300ms;
                font-size: 1.125rem;
                color: $secondary-color;
                font-weight: 400;
                font-family: "Poppins";
            }
        }
    }
    &__copyrights {
        p {
            margin: 0;
            color: $primary-color;
            text-transform: uppercase;
            font-size: rem-calc(10);
            line-height: rem-calc(20);
        }
        a {
            color: $black;
            transition: color 300ms;
            &:hover {
                color: $primary-color;
            }
        }
    }
}

.come {
    cursor: pointer;
    @include p;
    text-transform: uppercase;
    font-size: rem-calc(12);
    letter-spacing: 0.05em;
    a {
        color: $primary-color;
        display: flex;
        align-items: center;
        svg {
            stroke: $secondary-color;
            margin: auto 0;
            margin-right: 1rem;
        }
    }
}

.footer-map {
    #map {
        width: 100%;
        height: 40vh;
        margin-bottom: rem-calc(30);
        @include breakpoint(768px down) {
            margin-top: rem-calc(30);
        }
    }
}
;@import "sass-embedded-legacy-load-done:372";