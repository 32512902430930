html {
    scroll-behavior: smooth;
}

p {
    @include p;
}

.callout {
    @extend p;
}

form.valid input+small,
form.valid img+small {
    // @extend p;
}

form.valid legend {
    font-weight: $global-weight-bold !important;
    margin-left: 0.625rem;

}

input[type="date"]{
    position: relative;
    &::-webkit-calendar-picker-indicator {
    height: 100%;
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
}
}

.breadcrumbs {
    margin: rem-calc(20 0);
    li {
        @include p;
        font-size: rem-calc(12);
        a {
            color: $textes;
        }
    }
}

a {
    color: $primary-color;
    transition: color 300ms;
    &:hover {
        color: darken($primary-color, 15%);
    }
}

h1,
.titre-page {
    font-family: $body-font-family-variant;
    color: $primary-color;
    font-weight: $global-weight-medium;
    letter-spacing: -0.01em;
    font-size: rem-calc(48);
    @include breakpoint (medium down) {
        font-size: rem-calc(32);
        line-height: rem-calc(42);
    }
}

.bloc-chapo.rte p {
    font-size: rem-calc(24);
    line-height: rem-calc(33);
    letter-spacing: 0.025rem;
    margin-bottom: rem-calc(30);
    @include breakpoint(768px down) {
        font-size: rem-calc(18);
        line-height: rem-calc(30);
    }
}

.centre:not(.seccode) {
    margin: auto;
}

.plan {
    a {
        color: $secondary-color;
        &:hover {
            color: darken($secondary-color, 20%);
        }
    }
}

#rcontact-submit {
    width: 100%;
    button {
        @extend .rounded;
        display: table;
        margin: auto;
    }
}

hr {
    width: 100%;
}

form.themes {
    display: table;
    margin: 0;
    width: auto;
    text-align: center;
}

select,
input,
textarea,
select,
select#marque {
    letter-spacing: 0.075em;
}

// input#theme-submit {
//     background: $secondary-color;
//     &:hover {
//         background: $primary-color;
//     }
// }

:focus {
    outline: 0;
}

ul.pagination {
    display: table;
    margin: auto;
    li {
        &:before {
            display: none;
        }
    }
}

p {
    @include p;
}

.pagination .current {
    background: transparent !important;
    color: $secondary-color !important;
    padding: 0;
}

.pagination a,
.pagination button {
    color: darken($secondary-color, 20%);
}

ul.pagination li.current a,
ul.pagination li.current button {
    color: $secondary-color !important;
    font-weight: $global-weight-light !important;
}

.pagination a,
.pagination button {
    font-weight: $global-weight-light !important;
}

.page__container:not(.plugin) {
    background-color: $white;
    padding: rem-calc(10);
    @include breakpoint (large) {
        padding: rem-calc(60 100);
    }
}

.body__nav--public {
    background-color: $light-gray;
}

// .body__nav--public:not(.plugin) {
//     background-color: $light-gray;
// }

/*********************************************************

    TARTE AU CITRON 

*********************************************************/

span#tarteaucitronDisclaimerAlert {
    width: auto !important;
    display: block;
    text-align: center !important;
    margin: rem-calc(20) 0 !important;
    letter-spacing: 0.15em !important;
    font-family: $body-font-family !important;
    font-weight: 400 !important;
}

#tarteaucitronRoot #tarteaucitronAlertBig button {
    padding: rem-calc(10 20) !important;
    background: transparent !important;
    border: solid 1px $white !important;
    font-size: rem-calc(12) !important;
    letter-spacing: 0.085em;
    text-transform: uppercase;
    font-weight: 400 !important;
    font-family: $body-font-family !important;
    margin: 0 rem-calc(5) !important;
    transition: 300ms;
    color: $white !important;
    &:hover {
        background: $white !important;
        color: $primary-color !important;
    }
    @include breakpoint(768px down) {
        margin: rem-calc(5) 0 !important;
    }
}

#tarteaucitronRoot #tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronRoot #tarteaucitronAlertBig #tarteaucitronCloseAlert {
    font-size: rem-calc(12) !important;
    color: $white !important;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    font-weight: 400;
    &:hover {
        background: $white !important;
        color: $primary-color !important;
    }
}

span#tarteaucitronDisclaimerAlert {
    font-size: rem-calc(12) !important;
    letter-spacing: 0.07em !important;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
    width: 20% !important;
    background: $secondary-color !important;
    padding: 30px !important;
    right: 0 !important;
    left: auto !important;
    @media screen and (max-width: rem-calc(1200)) {
        width: 30% !important;
    }
    @media screen and (max-width: rem-calc(768)) {
        width: 20% !important;
    }
    @media screen and (max-width: rem-calc(470)) {
        width: auto !important;
        padding: 10px !important;
    }
}

#tarteaucitronRoot #tarteaucitronAlertBig button {
    font-size: 10px !important;
    margin: 0px auto !important;
    display: block;
    text-align: center;
    padding: 6px !important;
    border: none !important;
    border-bottom: solid 1px white !important;
    padding-bottom: 5px !important;
    margin-bottom: 5px !important;
}

button#tarteaucitronCloseAlert,
#tarteaucitronRoot #tarteaucitronAlertBig #tarteaucitronCloseAlert {
    font-size: 10px !important;
    text-align: center;
}

#tarteaucitronRoot #tarteaucitronAlertBig button:hover,
#tarteaucitronRoot #tarteaucitronAlertBig #tarteaucitronCloseAlert:hover {
    background: transparent !important;
    color: darken($secondary-color, 60%) !important;
    border-color: darken($secondary-color, 60%) !important;
}

#tarteaucitronRoot #tarteaucitronAlertBig button:hover .tarteaucitronCheck::before,
button#tarteaucitronCloseAlert,
#tarteaucitronRoot #tarteaucitronAlertBig button:hover .tarteaucitronCross::before {
    color: darken($secondary-color, 60%) !important;
}

;@import "sass-embedded-legacy-load-done:374";