.connexion-form {
    legend {
        width: 100%;
        text-align: center;
        color:$secondary-color;
    }
    button, .button {
        @extend .rounded, .small;
        display:table;
        margin:auto;
        @include breakpoint(1200px down) {
            font-size: rem-calc(12);
        }
    }
    form {
        border:solid 1px $medium-gray;
        padding:rem-calc(40);
    }
}

/* MDP OUBLIE */

form#oubli {
    div#rcode,
    div#roubli-submit {
        width: 100%;
    }
    .button {
        @extend .rounded, .small;
        display:table;
        margin:auto;
    }

    form#oubli {
        margin: 0;
        padding: 0;
    }

    fieldset#identification {
        margin: 0;
    }

    div#roubli-submit {
        margin: 0;
        margin-bottom: rem-calc(25);
    }

}



/******* INSCRIPTION ******/

button#inscription-submit {

  @extend .rounded, .hollow, .small;
}


;@import "sass-embedded-legacy-load-done:371";