.header__menu {
    position: relative;
    z-index: 99;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    background-color: $header-menu-background;
    padding: 0;
    &:after {
        position: absolute;
        background: $header-menu-after;
        content: "";
        height: 1px;
        width: 100%;
        left: 0;
        right: 0;
        margin: auto;
        bottom: rem-calc(0);
        z-index: 1;
        opacity: 0.3;
        @include breakpoint (medium down) {
            content: none;
        }
    }
    &.is-stuck {
        position: fixed;
        z-index: 99999;
        // animation-name: stickySlideDown;
        transition: none;
        box-shadow: 0px -5px 10px black;
        width: 100%;
        @if($use-transparent-header) {
            background: $header-menu-background-fixed;
        }
        @else {
            background: $header-menu-background;
        }
        @include breakpoint(1200px down) {
            display: none;
        }
        #logo {
            width: 148px;
            height: 58px;
            a {
                background-image: url('../../images/logo2.png');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 148px;
                height: 58px;
            }
            img {
                display: none;
            }
        }
    }
    @include breakpoint(1200px down) {
        padding: rem-calc(10);
    }
    li {
        padding: rem-calc(10 20);
        transition: 300ms;
        @include breakpoint(1630px down) {
            padding: rem-calc(10);
        }
        @include after-links;
        &.select,
        &:hover {
            &:after {
                width: rem-calc(30);
            }
        }
        li {
            &.select:after,
            &:hover:after {
                display: none;
            }
        }
        a {
            color: $header-menu-links-color;
            margin: auto;
            font-size: rem-calc(14);
            line-height: rem-calc(24);
            font-weight: $global-weight-semi-bold;
            text-transform: uppercase;
            transition: color 300ms;
            padding: 0 !important;
            @include breakpoint(1500px down) {
                font-size: rem-calc(12);
            }
            &:hover,
            &:focus {
                color: $header-menu-links-hover !important;
            }
        }
    }
    .submenu {
        li {
            border: solid 1px $medium-gray;
            border-bottom: 0;
            &:last-child {
                border-bottom: solid 1px $medium-gray;
            }
            padding:0;
            a {
                color: $primary-color;
                text-transform: none;
                margin: 0;
                display: block;
                width: 100%;
                height: 100%;
                padding: rem-calc(15) !important;
                font-size: rem-calc(14);
                &.aselect {
                    color: $secondary-color;
                }
            }
        }
    }
    button,
    .button {
        @include button( $style: 'hollow');
        @include border-radius(5px);
        margin: auto;
        border-color: $header-button-border-color !important;
        color: $header-button-color !important;
        padding: $header-button-padd;
        font-size: rem-calc(14);
        &:hover {
            border-color: $header-button-border-color-hover !important;
            color: $header-button-color-hover !important;
        }
        @include breakpoint(1200px down) {
            font-size: rem-calc(12);
            margin-right: 0;
        }
    }
    .menu-container {
        display: flex;
        place-content: center;
        @include breakpoint(1200px down) {
            display: none;
        }
    }
    .mainmenu {
        position: relative;
        display: inline-flex;
    }
    li.recherche:after {
        display: none !important;
    }
    @if($use-transparent-header) {
        svg {
            filter: brightness(0) invert(1);
        }
    }
    @else {
        svg,
        #logo img {
            filter: none;
        }
    }
    .dropdown.menu>li.is-dropdown-submenu-parent>a::after {
        display: none;
    }
    .dropdown.menu>li.is-active>a {
        color: $header-menu-links-hover !important;
    }
}
;@import "sass-embedded-legacy-load-done:376";