$width: 42px;
$height: 40px;
$nbItem: 5;
.bloc_share_this {
    margin: rem-calc(20 0 0);
}

.trigger.like-btn a {
    font-weight: 400;
    font-size: rem-calc(12);
}

.hide-for-small.bloc_top_social {
    padding: rem-calc(20 10 20 0);
    @include breakpoint(1200px down) {
        display: none;
    }
}

.container_share {
    display: flex;
    justify-content: flex-end;
    position: relative;
    float: right;
    margin: 0;
}

.tip {
    position: absolute;
    top: 50%;
    right: calc(100% + 10px);
    transform: translateY(-50%);
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: .1em;
    display: flex;
    align-items: center;
    &:after {
        content: "";
        width: 30px;
        height: 1px;
        background: $primary-color;
        margin: 0 0 0 8px;
    }
    @media only screen and (max-width: 600px) {
        display: none;
    }
}

.trigger {
    height: $height;
    line-height: $height;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    transition: color .2s, border .2s;
    a {
        color: $primary-color;
        text-decoration: none;
        transform: skew(10deg);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    i {
        font-size: 14px;
        line-height: $height;
        color: $primary-color;
    }
    &:hover {
        color: $secondary-color;
        border-color: $secondary-color !important;
        i,
        a {
            color: $secondary-color;
        }
    }
    &.share-btn {
        i {
            margin: 0 5px 0 0;
        }
    }
}

.like,
.share {
    transform: skew(-10deg);
    margin: 0 0 0 10px;
    .trigger {
        width: ($width * 2) - 2px;
        border: 0px solid $primary-color;
    }
    i {
        margin: 0 5px 0 0;
    }
}

.share {
    @media only screen and (min-width: 601px) {
        display: none;
    }
}

.share-window {
    transform: skew(-10deg);
    overflow: hidden;
    @media only screen and (max-width: 600px) {
        display: none;
    }
}

.share-bar {
    position: relative;
    width: ($width * 2) - 2px;
    height: $height;
    transition: width .4s ease .2s;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-width: 0px;
        border-style: solid;
        border-color: $primary-color $primary-color $primary-color transparent;
    }
    &:hover {
        width: ($width * $nbItem) - 5px;
        transition: width .4s ease;
        .trigger {
            &:nth-child(3) {
                left: ($width * 2) - 2px;
                transition: left .45s ease .06s, color .2s, border .2s;
            }
            &:nth-child(4) {
                left: ($width * 3) - 3px;
                transition: left .45s ease .12s, color .2s, border .2s;
            }
            &:nth-child(5) {
                left: ($width * 4) - 4px;
                transition: left .45s ease .18s, color .2s, border .2s;
            }
            &:nth-child(6) {
                left: ($width * 5) - 5px;
                transition: left .45s ease .24s, color .2s, border .2s;
            }
        }
    }
    .trigger {
        position: absolute;
        top: 0;
        width: $width;
        border-width: 0px;
        border-style: solid;
        border-color: transparent transparent transparent $primary-color;
        transition: left 0s ease .45s, color .2s, border .2s;
        &:nth-child(1) {
            left: 0;
        }
        &:nth-child(2) {
            left: $width - 1px;
        }
        &:nth-child(3) {
            left: ($width * 2) - 2px + 20px;
        }
        &:nth-child(4) {
            left: ($width * 3) - 3px + 20px;
        }
        &:nth-child(5) {
            left: ($width * 4) - 4px + 20px;
        }
        &:nth-child(6) {
            left: ($width * 5) - 5px + 20px;
        }
    }
}

.bloc_top_social .columns {
    padding: 0 !important;
}
;@import "sass-embedded-legacy-load-done:360";