.home-news {
    @include padd-top;
    @include padd-bot;
    background-color: $light-gray;
    &.immo {
        background-image: url('../../images/bg-immo.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        .ext-annonces__items {
            margin-bottom: 2.5rem;
        }
    }
    &__maintitle {
        margin-bottom: rem-calc(35);
        h2 {
            @include titles-homepage;
        }
    }
    &__button {
        font-weight: $global-weight-normal;
        font-size: rem-calc(16);
        line-height: rem-calc(26);
        color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            stroke: $primary-color;
        }
    }
    &__container {
        max-width: 625px;
        margin: auto;
        @include breakpoint (678px down) {
            max-width: 282px;
        }
    }
    &__item {
        background-color: $white;
        box-shadow: 4px 2px 66px -25px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 4px 2px 66px -25px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 4px 2px 66px -25px rgba(0, 0, 0, 0.3);
        margin-bottom: rem-calc(40);
        justify-content: space-between;
        min-height: rem-calc(395);
        // @include breakpoint(767px down) {
        //     width: calc(50% - 1.25rem) !important;
        //     margin-bottom: rem-calc(20);
        // }
        &.item-une {
            @include breakpoint(767px down) {
                width: calc(100% - 1.25rem) !important;
            }
            @include breakpoint(600px down) {
                width: calc(100% - 1.25rem) !important;
                flex-direction: column;
                .small-6 {
                    width: 100%;
                }
            }
        }
        @include breakpoint(375px down) {
            justify-content: space-between;
            .small-6 {
                height: auto !important;
            }
        }
    }
    &__img {
        display: flex;
        @include breakpoint(375px down) {
            min-height: rem-calc(150);
        }
        a {
            width: 100%;
        }
        img {
            object-fit: cover;
            height: 100%;
        }
    }
    &__title {
        font-size: rem-calc(16);
        line-height: rem-calc(26);
        color: $black;
        font-weight: $global-weight-semi-bold;
        letter-spacing: -0.025em;
        margin-bottom: rem-calc(8);
        @include breakpoint(1200px down) {
            font-size: rem-calc(14);
            line-height: rem-calc(22);
        }
    }
    &__themes {
        margin-bottom: rem-calc(5);
    }
    &__theme {
        @include theme;
        a {
            color: $dark-gray;
            &:hover {
                color: $primary-color;
            }
        }
    }
    &__enchere {
        font-weight: $global-weight-normal;
        color: #565656;
        font-size: rem-calc(14);
    }
    &__type {
        @include theme;
    }
    &__document {
        padding-bottom: rem-calc(30);
        font-size: rem-calc(14);
        line-height: rem-calc(24);
        letter-spacing: 0.05rem;
        font-weight: $global-weight-medium;
    }
    &__date {
        @include p;
        font-size: rem-calc(12);
        margin: auto;
        line-height: rem-calc(20);
        @include breakpoint(large down) {
            display: none;
        }
    }
    &__link {
        border: solid 1px $secondary-color;
        border-radius: 50%;
        transition: 300ms;
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem-calc(28) !important;
        height: rem-calc(28) !important;
        margin-left: auto;
        @include breakpoint(large down) {
            width: rem-calc(20) !important;
            height: rem-calc(20) !important;
        }
        &:hover {
            background: $secondary-color;
            border-color: $secondary-color;
            svg {
                fill: white;
            }
        }
        svg {
            fill: $secondary-color;
            transition: 300ms;
            margin-top: 1px;
        }
    }
    &__excerpt {
        @include p;
        font-weight: $global-weight-extra-light;
        margin-bottom: rem-calc(15);
        letter-spacing: 0.075em;
        @include breakpoint(large down) {
            font-size: rem-calc(12);
            line-height: rem-calc(20);
        }
        @include breakpoint(small down) {
            display: none;
        }
    }
    hr {
        background: $light-gray;
        opacity: 0.4;
    }
    .button {
        display: flex;
        width: fit-content;
        margin: auto;
        align-items: center;
        @include breakpoint(1024px down) {
            font-size: rem-calc(14);
        }
    }
}

.list-actus {
    margin-top: rem-calc(40);
}
;@import "sass-embedded-legacy-load-done:378";