/* Feuille de style des contenus gérés par le tinyMCE de la console */

.rte {
    ul,
    ol {
        @include p;
        list-style: none;
        margin-bottom: rem-calc(30);
        margin-left: rem-calc(30);
        li {
            margin-bottom: rem-calc(5);
            padding-left: rem-calc(10);
            position: relative;
            &:before {
                content: ">";
                position: absolute;
                left: rem-calc(-5);
                top: 0;
                transition: 300ms;
                font-size: 1.125rem;
                color: $primary-color;
                font-weight: 400;
                letter-spacing: .025rem;
                line-height: 1.875rem;
                font-family: "Poppins";
            }
            ul,
            ol {
                margin-top: rem-calc(5);
                list-style: none;
            }
            li {
                margin-bottom: rem-calc(5);
            }
        }
    }
    p,
    td {
        @include p;
    }
    h2 {
        font-family: $body-font-family-variant;
        font-weight: $global-weight-medium;
        margin: rem-calc(50 0 20);
        color: $primary-color;
    }
    h3 {
        font-weight: $global-weight-medium;
        color: $black;
        letter-spacing: -0.01em;
        margin: rem-calc(40 0 20);
    }
    h4 {
        font-weight: $global-weight-normal;
        color: $primary-color;
        letter-spacing: 0.025em;
        margin: rem-calc(30 0 20);
    }
    h5 {
        font-weight: $global-weight-medium;
        letter-spacing: 0.025em;
        color: $black;
        margin: rem-calc(20 0 10);
    }
    .accordion-title {
        font-size: rem-calc(30);
        letter-spacing: -0.010rem;
        color: $black;
        line-height: rem-calc(32);
        font-weight: $global-weight-normal;
    }
    .grid {
        display: grid;
    }
    .col2 {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px;
        @include breakpoint(small only) {
            grid-template-columns: 1fr;
            grid-gap: 0;
        }
    }
    .col3 {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 35px;
        @include breakpoint(small only) {
            grid-template-columns: 1fr;
            grid-gap: 0;
        }
    }
    .col4 {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
        @include breakpoint(medium down) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include breakpoint(medium down) {
            grid-template-columns: 1fr;
            grid-gap: 0;
        }
    }
    .col5 {
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 20px;
        @include breakpoint(medium down) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include breakpoint(medium down) {
            grid-template-columns: 1fr;
            grid-gap: 0;
        }
    }
    .col6 {
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 20px;
        @include breakpoint(medium down) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include breakpoint(medium down) {
            grid-template-columns: 1fr;
            grid-gap: 0;
        }
    }
}
;@import "sass-embedded-legacy-load-done:362";