/* Permet de faire un lien vers une ancre et de décaler le contenu qui est caché sous le header */

:target::before {
    content: '';
    display: block;
    height: 90px;
    margin-top: -90px;
}

.header {
    background-repeat: no-repeat;
    background-size: cover;
    @if($use-transparent-header) {
        position: relative;
        width: 100%;
        &:after {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(180deg, $black -60%, transparent);
            content: "";
            top: 0;
            left: 0;
            right: 0;
        }
    }
    @else {
        position: relative;
    }
    &__maintitle {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
            font-size: rem-calc(60);
            line-height: rem-calc(72);
            letter-spacing: -0.025em;
            font-weight: $global-weight-extra-light;
            text-transform: uppercase;
            color: $white;
            z-index: 999;
            padding: 4.5rem 0;
            margin: 0;
            @include breakpoint (medium down) {
                padding: 2.5rem .5rem;
                font-size: rem-calc(30);
                line-height: rem-calc(38);
                text-align: center;
            }
        }
    }
    &__container {
        margin: auto;
        position: relative;
        z-index: 9;
        @if($use-transparent-header) {
            padding: 0;
        }
        @else {
            padding: rem-calc(140 10);
        }
    }
    &__connexion--pub,
    &__connexion--pub .pub-connected {
        margin: auto;
        display: flex;
        width: auto;
        @include breakpoint (medium down) {
            display: none;
        }
        .espace-avocat {
            justify-content: center;
            width: rem-calc(180);
            height: rem-calc(85);
            border: none;
            background-color: $secondary-color;
            border-radius: 0;
            display: flex;
            align-items: flex-end;
            background-image: url('../../images/ordre.png');
            background-size: inherit;
            background-repeat: no-repeat;
            background-position: center 10px;
            color: $black;
            font-size: rem-calc(14);
            font-weight: $global-weight-bold;
            text-transform: uppercase;
            padding: rem-calc(10);
            transition: background-color ease-in-out 300ms, color ease-in-out 300ms;
            @include breakpoint (1400px down) {
                width: auto;
                font-size: rem-calc(12);
            }
            @include breakpoint (1280px down) {
                width: 86px;
                height: 69px;
                font-size: 0;
            }
            &:hover {
                background-color: darken($secondary-color, 10%);
                color: $primary-color;
            }
        }
        @include breakpoint(large down) {
            margin-right: 0;
        }
    }
    &__connexion--link {
        color: $secondary-color;
        text-align: center;
        font-size: rem-calc(12);
    }
    &__slider {
        .slider {
            &__item {
                &--photo {
                    img {
                        min-height: 800px;
                        height: 90vh;
                        object-fit: cover;
                        width: 100%;
                        @include breakpoint (medium down) {
                            min-height: 500px;
                            height: calc(100vh - 75px);
                        }
                    }
                }
            }
        }
        .slick-dots {
            bottom: 45px;
            li {
                margin: 0 14px;
                button:before {
                    color: $white;
                    opacity: 0.75;
                }
            }
            li.slick-active button:before {
                opacity: 1;
                color: $white;
            }
        }
        .slick-prev,
        .slick-next {
            z-index: 999;
            &:before {
                content: url('../../images/slick-arrow.png');
            }
        }
        .slick-next {
            right: 30px;
        }
        .slick-prev {
            left: 30px;
        }
        .slick-prev:before {
            content: url('../../images/slick-arrow-prev.png');
        }
    }
    &__txt {
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: rem-calc(90);
        @include breakpoint(medium down) {
            justify-content: flex-start;
        }
        .header__accroche {
            color: $white;
            font-family: $body-font-family;
            font-size: rem-calc(60);
            line-height: rem-calc(72);
            letter-spacing: -0.025em;
            font-weight: $global-weight-extra-light;
            padding: rem-calc(80 10 0 10);
            span {
                font-weight: $global-weight-semi-bold;
            }
            @include breakpoint(medium down) {
                text-align: left;
                padding-left: 1.25rem;
                padding-top: 20vh;
            }
            @include breakpoint(small down) {
                font-size: rem-calc(24);
                line-height: rem-calc(30);
                letter-spacing: -0.025rem;
            }
        }
        .header__subaccroche {
            color: $white;
            font-family: $body-font-family;
            font-size: rem-calc(24);
            line-height: rem-calc(30);
            letter-spacing: 0.3em;
            font-weight: $global-weight-extra-light;
            @include breakpoint(1200px down) {
                font-size: rem-calc(18);
                line-height: rem-calc(26);
            }
        }
    }
    &__scroll {
        width: rem-calc(60);
        height: rem-calc(60);
        border-radius: rem-calc(30);
        border: 1px solid white;
        display: flex;
        svg {
            margin: auto;
        }
    }
    &-intern {
        min-height: rem-calc(300);
        .header__accroche {
            font-size: rem-calc(50);
            line-height: rem-calc(60);
            @include breakpoint(1200px down) {
                font-size: rem-calc(40);
                line-height: rem-calc(50);
            }
            @include breakpoint(768px down) {
                font-size: rem-calc(30);
                line-height: rem-calc(40);
            }
        }
        .header__container {
            padding: 0;
        }
    }
    #example-dropdown {
        padding: rem-calc(35);
        .button {
            padding: rem-calc(10);
        }
    }
    #extranet-menu {
        @include breakpoint(960px down) {
            left: 0 !important;
            right: 0;
            width: 100%;
            margin: auto;
        }
    }
    #logo {
        min-height: rem-calc(70);
        display: flex;
        padding-left: rem-calc(20);
        width: rem-calc(180);
        a {
            margin: auto;
        }
        img {
            position: absolute;
            top: 0.25rem;
            left: rem-calc(20);
            @include breakpoint (1400px down) {
                width: rem-calc(110);
            }
        }
        @include breakpoint(1400px down) {
            max-width: rem-calc(110);
        }
    }
}

.sub-header {
    max-width: rem-calc(1045);
    margin-left: auto;
    margin-right: auto;
    &__maintitle {
        @include titles-homepage;
        margin-bottom: rem-calc(25);
    }
    &__title {
        color: $white;
        font-family: $body-font-family;
        font-size: rem-calc(24);
        line-height: rem-calc(30);
        letter-spacing: -0.05em;
        font-weight: $global-weight-light;
        text-align: center;
        margin: 0;
        @include breakpoint(large down) {
            font-size: rem-calc(25);
            line-height: rem-calc(28);
        }
    }
    &__form {
        border-radius: $global-radius;
        max-width: rem-calc(1200);
        margin: auto;
        background: $primary-color;
        .icon {
            stroke: $secondary-color;
            stroke-width: 1px;
        }
    }
    &__submit {
        display: contents;
        cursor: pointer;
    }
    &__more {
        text-align: center;
        @include breakpoint (large) {
            text-align: right;
        }
        a {
            padding: rem-calc(12 20);
        }
    }
    .form-search {
        gap: rem-calc(20);
        @include breakpoint (large down) {
            margin: rem-calc(20) auto rem-calc(20) auto;
        }
        @include breakpoint(768px down) {
            display: grid;
            gap: rem-calc(10);
        }
        svg {
            @include breakpoint(768px down) {
                margin: auto;
            }
        }
    }
    input,
    select {
        margin: auto !important;
        min-height: rem-calc(40);
        border-radius: rem-calc(5);
        @include breakpoint(768px down) {
            width: 100% !important;
        }
    }
    select option:first-child {
        font-size: rem-calc(15);
        color: $primary-color;
        font-family: $body-font-family;
    }
    select {
        background-image: var(--chevron);
        cursor: pointer;
    }
}

.extranet-btn {
    margin: auto rem-calc(10);
    display: flex;
    place-content: center;
    @include breakpoint(767px down) {
        margin: auto 0;
        max-width: rem-calc(50) !important;
    }
    li {
        list-style: none;
        border-bottom: solid 1px $medium-gray;
        a {
            color: $primary-color;
            &:hover {
                color: $secondary-color;
            }
        }
        &:after {
            display: none !important;
        }
    }
}

.pub-connexion {
    margin-left: rem-calc(20);
    display: flex;
    color: $white;
    i {
        margin: auto;
    }
}

.menu-container ul li ul {
    width: 0;
    height: 0;
    opacity: 0;
}

.no-js .menu-container {
    display: none;
}

.body__home--private .header::after {
    content: none;
}


/* debug anim menu */

.is-dropdown-submenu {
    background: white;
    border: 0 !important;
    li {
        background: white;
    }
}

.is-dropdown-submenu.js-dropdown-active {
    opacity: 1;
    animation-name: none;
}
;@import "sass-embedded-legacy-load-done:375";