$use-transparent-header: true;
$header-menu-background: transparent;
$header-menu-background-fixed: $primary-color;
$header-index-circle-border: $secondary-color;
$header-menu-links-color: white;
$header-menu-links-hover:$secondary-color;
$header-menu-selector:$secondary-color;
$header-menu-after:white;
$header-button-border-color:$primary-color;
$header-button-color: $primary-color;
$header-button-border-color-hover:darken($primary-color, 10%);
$header-button-color-hover:darken($primary-color, 10%);
$header-button-padd:rem-calc(10 15);
$button-color:white;
$button-border:$header-button-border-color;
;@import "sass-embedded-legacy-load-done:335";