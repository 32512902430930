.card {
    border:0;
    border-radius: rem-calc(5);
    .card-divider {
        background: $secondary-color;
        @include p;
        color:$white;
        font-weight: $global-weight-bold;
    }
    .card-section {
        border:solid 1px $medium-gray;
    }
}

.card-container {
    width: 100% !important;
}
;@import "sass-embedded-legacy-load-done:370";